import React from 'react';
import './legal.css';

const TermsOfService = () => {
   return (
      <div className="legal-container">
         <h1 className="medium-title">Allgemeine Geschäftsbedingungen</h1>

         {/* § 1 Vertragspartner, Geltungsbereich */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 1 Vertragspartner, Geltungsbereich</h2>
            <p className="medium-text">
               1. Vertragspartner sind die <strong>Adestoria Agentur</strong>, vertreten durch den Inhaber Enriko Korezki, und der jeweilige Kunde.
            </p>
            <p className="medium-text">
               2. Die Agentur erbringt Dienstleistungen in den Bereichen Video, Social Media, Marketing, Webdesign, Webentwicklung sowie Webanalytics.
            </p>
            <p className="medium-text">
               3. Diese AGB gelten für alle Verträge, Angebote, Lieferungen und Leistungen zwischen der Agentur und dem Kunden.
            </p>
            <p className="medium-text">
               4. Der Kunde erklärt, die Leistungen der Agentur ausschließlich für geschäftliche Zwecke zu erwerben. Entgegenstehende Geschäftsbedingungen des Kunden gelten nur, wenn die Agentur deren Geltung ausdrücklich schriftlich zustimmt.
            </p>
         </div>

         {/* § 2 Vertragsgegenstand */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 2 Vertragsgegenstand</h2>
            <p className="medium-text">
               <strong>Leistungsumfang:</strong> Grundlage des Vertrags ist die im Angebot beschriebene Leistung. Änderungen oder Ergänzungen des Leistungsumfangs bedürfen der schriftlichen Vereinbarung.
            </p>
            <p className="medium-text">
               <strong>Veröffentlichungen:</strong> Der Kunde prüft und genehmigt von der Agentur vorgeschlagene Inhalte spätestens 48 Stunden vor Veröffentlichung. Erfolgt keine Reaktion, gilt die Genehmigung als erteilt.
            </p>
            <p className="medium-text">
               <strong>Leistungsbestimmungsrecht:</strong> Die Agentur behält sich das Recht vor, gestalterische und technische Entscheidungen zu treffen, sofern keine anderslautenden schriftlichen Vereinbarungen bestehen.
            </p>
            <p className="medium-text">
               <strong>Kein Erfolgsgarant:</strong> Die Agentur schuldet keine Garantie für bestimmte Erfolge wie Umsatzsteigerungen, Reichweitenwachstum oder Conversion-Zahlen. Die Dienstleistung ist als Unterstützung zur Zielerreichung zu verstehen.
            </p>
            <p className="medium-text">
               <strong>Subunternehmer:</strong> Die Agentur darf Subunternehmer einsetzen, ohne den Kunden darüber gesondert zu informieren. Ein Anspruch auf persönliche Leistungserbringung durch bestimmte Mitarbeiter besteht nicht.
            </p>
            <p className="medium-text">
               <strong>Zusatzleistungen:</strong> Dienstleistungen, die nicht im Angebot enthalten sind, wie zusätzliche Programmierungen, Lizenzgebühren, Premium-Tools oder Werbebudgets, sind vom Kunden gesondert zu tragen.
            </p>
         </div>

         {/* § 3 Vergütung */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 3 Vergütung</h2>
            <p className="medium-text">
               <strong>Höhe der Vergütung:</strong> Die Vergütung ergibt sich aus dem Angebot. Alle Preise verstehen sich netto zuzüglich der gesetzlichen Umsatzsteuer.
            </p>
            <p className="medium-text">
               <strong>Zahlungsmodalitäten:</strong> Die Zahlung erfolgt per Überweisung, SEPA-Lastschrift oder über einen externen Zahlungsdienstleister. Rechnungsbeträge sind sofort nach Erhalt der Rechnung fällig, sofern nichts anderes vereinbart wurde.
            </p>
            <p className="medium-text">
               <strong>Zusatzkosten:</strong> Kosten für externe Dienste wie Anzeigenkampagnen oder Lizenzen werden dem Kunden direkt in Rechnung gestellt und sind nicht in der Vergütung enthalten.
            </p>
            <p className="medium-text">
               <strong>Verzug und Verzugszinsen:</strong> Gerät der Kunde mit Zahlungen in Verzug, berechnet die Agentur Verzugszinsen in Höhe von 5 % des ausstehenden Betrags pro Monat. Zusätzlich behält sich die Agentur das Recht vor, Leistungen bis zur vollständigen Begleichung auszusetzen.
            </p>
         </div>

         {/* § 4 Mitwirkungspflichten des Kunden */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 4 Mitwirkungspflichten des Kunden</h2>
            <p className="medium-text">
               <strong>Bereitstellung von Daten:</strong> Der Kunde stellt sicher, dass alle für die Vertragserfüllung erforderlichen Informationen, Daten und Zugänge rechtzeitig bereitgestellt werden.
            </p>
            <p className="medium-text">
               <strong>Reaktionszeiten:</strong> Rückfragen oder Genehmigungen durch die Agentur sind vom Kunden innerhalb von 3 Werktagen zu beantworten. Verzögerungen führen zu einer entsprechenden Verschiebung von Terminen und Fristen.
            </p>
            <p className="medium-text">
               <strong>Technische Voraussetzungen:</strong> Der Kunde stellt sicher, dass die erforderlichen technischen Voraussetzungen, wie Internetzugänge oder Zugänge zu Drittplattformen, bestehen.
            </p>
            <p className="medium-text">
               <strong>Revisionsrunden:</strong> Eine Korrekturschleife ist im Angebot enthalten. Zusätzliche Änderungswünsche werden gesondert berechnet.
            </p>
            <p className="medium-text">
               <strong>Rechtstexte und Compliance:</strong> Der Kunde trägt die Verantwortung für die Bereitstellung und rechtliche Prüfung erforderlicher Texte wie Impressum, Datenschutzerklärung oder Cookie-Richtlinien.
            </p>
         </div>

         {/* § 5 Nutzungsrechte */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 5 Nutzungsrechte</h2>
            <p className="medium-text">
               <strong>Eigentumsvorbehalt:</strong> Bis zur vollständigen Zahlung verbleiben alle erstellten Inhalte, Designs, Codes und Medien im Eigentum der Agentur.
            </p>
            <p className="medium-text">
               <strong>Einfache Nutzungsrechte:</strong> Nach vollständiger Zahlung erhält der Kunde ein einfaches, nicht exklusives Nutzungsrecht für die vereinbarten Zwecke.
            </p>
            <p className="medium-text">
               <strong>Änderungen und Weitergabe:</strong> Änderungen der Arbeitsergebnisse oder deren Weitergabe an Dritte bedürfen der schriftlichen Zustimmung der Agentur.
            </p>
         </div>

         {/* § 6 Haftung */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 6 Haftung</h2>
            <p className="medium-text">
               <strong>Haftungsumfang:</strong> Die Agentur haftet nur für Schäden, die vorsätzlich oder grob fahrlässig verursacht wurden.
            </p>
            <p className="medium-text">
               <strong>Datenverlust:</strong> Die Haftung für Datenverlust ist auf den typischen Wiederherstellungsaufwand begrenzt, der bei regelmäßiger Datensicherung eingetreten wäre.
            </p>
            <p className="medium-text">
               <strong>Haftungsausschluss für Drittplattformen:</strong> Die Agentur übernimmt keine Haftung für Entscheidungen oder Maßnahmen von Drittplattformen (z. B. Kontosperrungen, Algorithmusänderungen), die den Erfolg der vereinbarten Leistungen beeinflussen.
            </p>
         </div>

         {/* § 7 Laufzeit und Kündigung */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 7 Laufzeit und Kündigung</h2>
            <p className="medium-text">
               <strong>Vertragslaufzeit:</strong> Sofern nicht anders vereinbart, beträgt die Vertragslaufzeit drei Monate und verlängert sich automatisch, wenn nicht mindestens zwei Monate vor Ablauf schriftlich gekündigt wird.
            </p>
            <p className="medium-text">
               <strong>Außerordentliche Kündigung:</strong> Das Recht zur außerordentlichen Kündigung bleibt unberührt. Gründe hierfür können beispielsweise Zahlungsverzug oder wiederholte Verstöße gegen Mitwirkungspflichten sein.
            </p>
         </div>

         {/* § 8 Datenschutz */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 8 Datenschutz</h2>
            <p className="medium-text">
               <strong>Datenverarbeitung:</strong> Die Agentur erhebt und verarbeitet personenbezogene Daten ausschließlich zur Vertragserfüllung gemäß der DSGVO.
            </p>
            <p className="medium-text">
               <strong>Löschung von Daten:</strong> Nach Vertragsende werden alle personenbezogenen Daten gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten bestehen.
            </p>
            <p className="medium-text">
               <strong>Datenschutzrichtlinie:</strong> Weitere Details zur Datenverarbeitung finden sich in der Datenschutzerklärung.
            </p>
         </div>

         {/* § 9 Höhere Gewalt */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 9 Höhere Gewalt</h2>
            <p className="medium-text">
               <strong>Definition:</strong> Ereignisse wie Naturkatastrophen, Pandemien, Streiks oder technische Ausfälle entbinden beide Parteien von der Erfüllung ihrer vertraglichen Verpflichtungen für die Dauer des Ereignisses.
            </p>
            <p className="medium-text">
               <strong>Wiederaufnahme der Leistungen:</strong> Nach Wegfall des Ereignisses wird die Erbringung der Leistungen fortgesetzt.
            </p>
         </div>

         {/* § 10 Vertraulichkeit */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 10 Vertraulichkeit</h2>
            <p className="medium-text">
               Beide Parteien verpflichten sich, alle vertraulichen Informationen, die im Rahmen der Zusammenarbeit ausgetauscht werden, geheim zu halten.
            </p>
            <p className="medium-text">
               Die Agentur ist berechtigt, den Kunden als Referenz zu nennen, sofern nichts anderes schriftlich vereinbart wurde.
            </p>
         </div>

         {/* § 11 Streitbeilegung */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 11 Streitbeilegung</h2>
            <p className="medium-text">
               <strong>Mediation:</strong> Die Parteien verpflichten sich, bei Streitigkeiten zunächst eine Mediation durchzuführen, bevor rechtliche Schritte eingeleitet werden.
            </p>
            <p className="medium-text">
               <strong>Gerichtsstand:</strong> Gerichtsstand ist der Sitz der Agentur, sofern gesetzlich zulässig.
            </p>
         </div>

         {/* § 12 Schlussbestimmungen */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 12 Schlussbestimmungen</h2>
            <p className="medium-text">
               <strong>Anwendbares Recht:</strong> Es gilt deutsches Recht unter Ausschluss des UN-Kaufrechts.
            </p>
            <p className="medium-text">
               <strong>Salvatorische Klausel:</strong> Sollten einzelne Bestimmungen dieser AGB unwirksam sein, bleiben die übrigen Bestimmungen unberührt.
            </p>
         </div>
      </div>
   );
};

export default TermsOfService;
