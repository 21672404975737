import React from 'react';
import './Footer.css';
import logoSrc from '../assets/img/logo2.svg';
import TrustpilotWidget from '../components/trustpilotWidget';

const Footer = () => {
    return (
        <footer className="footer">
            {/* Left: Trustpilot Widget */}
            <div className="footer-section footer-left">
                <TrustpilotWidget />
            </div>

            {/* Center: Logo */}
            <div className="footer-section footer-center">
                <img src={logoSrc} alt="Logo" className="footer-logo" />
            </div>

            {/* Right: Links */}
            <div className="footer-section footer-right">
                <a href="/impressum">Impressum</a>
                <a href="/datenschutz">Datenschutz</a>
                {/*<a href="/cookies">Cookies</a>*/}
                <a href="/agb">AGB</a>
            </div>
        </footer>
    );
};

export default Footer;
