import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/img/logo2.svg';
import CalendlyButtons from '../components/forms/CalendlyForm';
import TrustpilotWidget from "../components/trustpilotWidget";



const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownClicked, setDropdownClicked] = useState(false); // Tracks click behavior
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const hoverTimeout = useRef(null);

    // Toggle menu
    const toggleMenu = () => setMenuOpen(!menuOpen);

    // Handle dropdown click
    const handleDropdownClick = () => {
        setDropdownClicked(!dropdownClicked); // Toggle clicked state
        setDropdownOpen(!dropdownOpen); // Toggle dropdown visibility
        clearTimeout(hoverTimeout.current); // Prevent hover timeout from closing it
    };

    // Handle hover enter
    const handleDropdownEnter = () => {
        if (!dropdownClicked) {
            clearTimeout(hoverTimeout.current); // Clear any pending close action
            setDropdownOpen(true); // Open dropdown
        }
    };

    // Handle hover leave
    const handleDropdownLeave = () => {
        if (!dropdownClicked) {
            hoverTimeout.current = setTimeout(() => {
                setDropdownOpen(false); // Close dropdown after timeout
            }, 3000); // 3 seconds timeout
        }
    };

    // Handle outside click
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownClicked && !event.target.closest('.dropdown')) {
                setDropdownClicked(false); // Reset click state
                setDropdownOpen(false); // Close dropdown
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => document.removeEventListener('click', handleOutsideClick);
    }, [dropdownClicked]);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => setIsSticky(window.scrollY > 50);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const closeMenu = () => setMenuOpen(false);

    return (
        <header className={`header ${menuOpen ? 'menu-open' : ''} ${isSticky ? 'sticky-header' : ''}`}>
            {/* Desktop Contact Info */}
            {!menuOpen && !isMobile && (
                <div className="desktop">
                    <a href="tel:01523 2058789" className="medium-text link-hover fade-in-left animation-slow">
                        01523 2058789
                    </a>
                </div>
            )}

            {/* Logo */}
            <div className={`logo fade-in-top animation-slow ${menuOpen ? 'logo-left fade-in-left animation-slow' : ''}`}>
                <Link to="/" onClick={closeMenu}>
                    <img src={logo} alt="ADESTORIA Logo" draggable="false" />
                </Link>
            </div>

            {/* Mobile Menu Icon */}
            {isMobile && (
                <button className="mobile-menu-icon" onClick={toggleMenu} style={{ zIndex: 10000 }}>
                    {menuOpen ? '✕' : '☰'}
                </button>
            )}

            {/* Desktop Button */}
            {!isMobile && (
                <button className="button small sheen fade-in-right animation-slow" onClick={toggleMenu}>
                    {menuOpen ? 'ZURÜCK' : 'MENÜ'}
                </button>
            )}

            {/* Navigation Menu */}
            {menuOpen && (
                <nav className={`small-text ${isMobile ? 'mobile-menu' : 'nav-menu'}`}>
                    {/* Mobile Logo */}
                    {isMobile && (
                        <div className="mobile-menu-logo">
                            <Link to="/" onClick={closeMenu}>
                                <img src={logo} alt="ADESTORIA Logo" draggable="false" />
                            </Link>
                        </div>
                    )}

                    {/* Dropdown Menu for Desktop */}
                    {!isMobile && (
                        <div
                            className="dropdown desktop"
                            onMouseEnter={handleDropdownEnter}
                            onMouseLeave={handleDropdownLeave}
                        >
                            <div className="dropdown-trigger" onClick={handleDropdownClick}>
                                LEISTUNGEN <span className={`arrow ${dropdownOpen ? 'arrow-rotated' : ''}`}>▼</span>
                            </div>
                            {dropdownOpen && (
                                <div
                                    className="dropdown-content"
                                    onMouseEnter={handleDropdownEnter} // Keeps it open
                                    onMouseLeave={handleDropdownLeave} // Starts hover timeout
                                >
                                    <Link to="/we-create" onClick={closeMenu}>WIR KREIEREN</Link>
                                    <Link to="/we-market" onClick={closeMenu}>WIR VERMARKTEN</Link>
                                    <Link to="/we-optimize" onClick={closeMenu}>WIR OPTIMIEREN</Link>
                                </div>
                            )}
                        </div>
                    )}

                    {/* Mobile Dropdown */}
                    {isMobile && (
                        <div className="dropdown-mobile">
                            <div
                                className="dropdown-trigger-mobile"
                                onClick={() => setDropdownOpen(!dropdownOpen)} // Toggle links
                            >
                                LEISTUNGEN <span className={`arrow ${dropdownOpen ? 'arrow-rotated' : ''}`}>▼</span>
                            </div>
                            <div className={`mobile-dropdown-links ${dropdownOpen ? 'open' : ''}`}>
                                <Link to="/we-create" onClick={closeMenu}>WIR KREIEREN</Link>
                                <Link to="/we-market" onClick={closeMenu}>WIR VERMARKTEN</Link>
                                <Link to="/we-optimize" onClick={closeMenu}>WIR OPTIMIEREN</Link>
                            </div>

                        </div>

                    )}



                    {/* Additional Links */}
                    <Link to="/uberuns" onClick={closeMenu}>ÜBER UNS</Link>
                    <Link to="/kontakt" onClick={closeMenu}>KONTAKT</Link>

                    <div className="mobile-nav-trustpilot mobile">
                        <TrustpilotWidget />
                    </div>

                    {/* Mobile Button Container */}
                    <div className="mobile-button-container mobile">
                        <a href="tel:015232058789" className="mobile-button mobile-button-call">Ruf uns an</a>
                        {/* Calendly Button */}
                        <CalendlyButtons
                            buttonText="TERMIN"
                            smallButton
                            customClass="mobile-button-termin"
                        />
                    </div>



                </nav>
            )}
        </header>
    );
};

export default Header;
