import React, { useEffect } from 'react'; // Ensure useEffect is imported
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Create from './pages/create';
import Market from './pages/market';
import Optimize from './pages/optimize';
import Testground from './pages/testground';
import './App.css';
//legal
import Impressum from './pages/legal/impressum';
import PrivacyPolicy from './pages/legal/privacy-policy';
import TermsOfUse from './pages/legal/terms-of-use';
import ComingSoon from './components/comingSoon';


function App() {
  // Animation on Viewport
  useEffect(() => {
    // Intersection Observer options
    const observerOptions = {
      root: null, // Observe within the viewport
      threshold: 0.1, // Trigger when 10% of the element is visible
    };

    // Intersection Observer callback
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove("hidden"); // Remove 'hidden' to trigger animation
          observer.unobserve(entry.target); // Stop observing once triggered
        }
      });
    }, observerOptions);

    // Select and observe all elements with the 'hidden' class
    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []); // Ensure useEffect is within the functional component

  return (
    <Router>
      <Header />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />

          {/** In Development */}
          {/*<Route path="/we-create" element={<Create />} />
          <Route path="/we-market" element={<Market />} />
          <Route path="/we-optimize" element={<Optimize />} />
          <Route path="/uberuns" element={<About />} />
          <Route path="/kontakt" element={<Contact />} />
          <Route path="/testground" element={<Testground />} />*/}
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenschutz" element={<PrivacyPolicy />} />
          <Route path="/agb" element={<TermsOfUse />} />

          {/**404 Comming soon */}
          <Route path="*" element={<ComingSoon />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
