import React, { useState, useEffect } from 'react';
import grogu from '../assets/gifs/grogu.gif';
import groguActive from '../assets/gifs/grogu-active.gif';
import './yoda.css';

const YodaPopup = () => {
   const [showGif, setShowGif] = useState(false);
   const [showPopup, setShowPopup] = useState(false);
   const [activeGif, setActiveGif] = useState(grogu);

   useEffect(() => {
      const now = new Date().getTime();
      const lastShown = parseInt(localStorage.getItem('yodaLastShown'), 10);

      let delay = 30000; // Default 30 seconds for first-time visitors
      if (lastShown && now - lastShown < 300000) { // If shown within the last 5 minutes (300,000 ms)
         delay = Math.max(300000 - (now - lastShown), 0); // Adjust delay for returning visitors
      }


      const timer = setTimeout(() => {
         setShowGif(true);
         localStorage.setItem('yodaLastShown', new Date().getTime()); // Save the last shown time
      }, delay);

      return () => clearTimeout(timer); // Cleanup timer
   }, []);

   useEffect(() => {
      if (showGif && !showPopup) {
         const removeTimer = setTimeout(() => {
            setShowGif(false); // Remove Yoda after 10 seconds
         }, 10000);

         return () => clearTimeout(removeTimer); // Cleanup timer
      }
   }, [showGif, showPopup]);

   const openPopup = () => {
      setShowPopup(true);
      setActiveGif(groguActive); // Switch to the active GIF on click
   };

   const closePopup = () => {
      setShowPopup(false);
      setShowGif(false); // Hide Yoda completely
      document.body.style.overflow = ''; // Re-enable scrolling
   };

   useEffect(() => {
      if (showPopup) {
         document.body.style.overflow = 'hidden'; // Disable scrolling when popup is open
      } else {
         document.body.style.overflow = ''; // Enable scrolling
      }
   }, [showPopup]);

   return (
      <>
         {showGif && (
            <div className="yoda-gif-container" onClick={openPopup}>
               <img src={activeGif} alt="Baby Yoda" className="yoda-gif" />
            </div>
         )}

         {showPopup && (
            <>
               {/* Overlay to close the popup when clicking outside */}
               <div className="popup-overlay" onClick={closePopup}></div>

               {/* Calendly iframe */}
               <div className="calendly-inline-container">
                  <button className="popup-close" onClick={closePopup}>
                     ✖
                  </button>
                  <iframe
                     src="https://calendly.com/adestoria/callback-service"
                     title="Calendly"
                     style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                     }}
                  ></iframe>
               </div>
            </>
         )}
      </>
   );
};

export default YodaPopup;
