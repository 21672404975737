import React, { useState } from "react";
import "./home.css";
import CalendlyButtons from '../components/forms/CalendlyForm';
import YodaPopup from '../components/yoda';
import LogoCarousel from "../components/logo-carousel";
import gifSrc from '../assets/gifs/old-lady-sunglasses.gif';
import CallbackForm from "../components/forms/callBackForm";
import Faq from "../components/faq";
import ContactDetails from "../components/ContactDetails";
import TrustpilotWidget from "../components/trustpilotWidget";



const Home = () => {
    const [showGif, setShowGif] = useState(false);

    const showGifWithTimer = () => {
        setShowGif(true);
        // Set a timer to hide the GIF after 5 seconds (5000 ms)
        setTimeout(() => {
            setShowGif(false);
        }, 5000);
    };

    return (
        <>
            <section className="hero-section section-style">
                <video className="background-video" autoPlay loop muted>
                    <source src="/video/melbournevideo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="hero-content">
                    <h1 className="large-title fade-in-bottom animation-slow not-selectable">WIE STEROIDEN <br />FÜR's BUSINESS</h1>

                    {/* Desktop/Tablet */}
                    <p className="fade-in-bottom animation-slow desktop">
                        wachsen in einer "angst" wirtschaft ist f*cking hart. wir machen <br />
                        das ganze einfacher, planbarer und weniger stressig.
                    </p>

                    {/* Mobile */}
                    <p className="fade-in-bottom animation-slow mobile">
                        wachsen in einer "angst" wirtschaft ist<br /> f*cking hart. wir machen das ganze<br />
                        einfacher, planbarer und <br />weniger stressig.
                    </p>

                    <CalendlyButtons buttonText="JETZT BUCHEN" standalone={true} />

                    {/* Small text elements below the button */}
                    <div className="hero-small-text fade-in-bottom hidden animation-delay-long">
                        <TrustpilotWidget />

                        <div className="small-text-item" onClick={showGifWithTimer} style={{ cursor: "pointer" }}>
                            Total legal! Sogar meine Oma würde zustimmen!
                        </div>
                    </div>
                </div>

                {/* Add YodaPopup inside the Hero Section */}
                <YodaPopup />
            </section>


            {/* SocialTrust Section */}
            <section className="section-style trust-section">
                <div className="video-box fade-in-bottom animation-delay-longer">
                    <video controls>
                        <source src="/video/sample.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div>

                    <LogoCarousel />
                    <br />
                    <CalendlyButtons
                        buttonText="DIREKT KONTAKT AUFNEHMEN"
                        standalone={true}
                        style={{ padding: '20px 150px' }}
                    />
                </div>
            </section>

            {/* Why Section */}
            <section className="section-style why-section">
                <h2 className="medium-title fade-in-bottom hidden">
                    Die einzige Agentur die<br />
                    sie brauchen werden
                </h2>

                <br />


                <div className="why-content fade-in-bottom hidden">
                    <p>
                        Während die meisten Agenturen einfach Geld auf das Problem
                        werfen und hoffen, dass die aufgeblasenen Ergebnisse
                        den Kunden zufriedenstellen, fragen wir uns:
                        <br />
                        <br />
                        Warum nicht einen smarteren Ansatz wählen?
                        Warum nicht deine bestehenden Prozesse
                        datengetrieben analysieren und genau herausfinden,
                        was dein Business wirklich braucht, um zu wachsen?
                        Nicht nur kurzfristig, sondern so, dass du auch Jahre
                        nach einem Projekt von den Ergebnissen profitierst.
                        <br />
                        <br />
                        <br />
                        <br />
                        <span className="bold-text">Resultate die DU von einer Agentur erwarten solltest: </span>
                    </p>
                </div>

                {/* Add the three blocks */}
                <div className="why-blocks fade-in-bottom hidden">
                    <div className="why-block why-block-left">
                        <h3>ERHÖHUNG VON<br />20-40% ROAS</h3>
                        <p>
                            Durch gezielte Strukturierung deiner Website, um deinem Brand gerecht zu werden.
                        </p>
                    </div>

                    <div className="why-block why-block-main ">
                        <h3>10-30% MEHR<br />CONVERSIONS</h3>
                        <p>
                            Durch gezielte CTA-Optimierungen und datengetriebene A/B-Tests.
                        </p>
                    </div>

                    <div className="why-block why-block-left">
                        <h3>DURCHSCHN. 20-50%<br />MEHR ROI</h3>
                        <p>
                            In den nächsten 3–6 Monaten bei fokussierter Webanalyse und entsprechenden Anpassungen.
                        </p>
                    </div>
                </div>


                {/** CTA Container */}
                <div className="why-cta-container fade-in-bottom hidden">
                    <h3 className="small-title why-title">Aber reden tun alle.</h3>
                    <p className="medium-text">Überzeug dich selbst in einem kostenfreien Gespräch.</p>
                    <br />
                    <CalendlyButtons buttonText="JETZT BUCHEN" standalone={true}
                    />
                </div>
            </section>

            {/* WE categories */}
            {/* WE CREATE */}

            <section className="section-style we-create fade-in-bottom hidden">

                {/* Desktop */}
                <div className="desktop">
                    <h2 className="medium-title">Was Bieten Wir?</h2>
                    <h3 className="small-title">Wir Kreieren</h3>

                    {/* First Row */}
                    <div className="we-box-row">

                        {/**This box needs to be on the left */}
                        <div className="we-box-medium we-box-left fade-in-left hidden">
                            <h3 className="we-box-title">Webdesign</h3>
                            <p className="we-box-text">
                                Ein marktführendes Design, das Kunden anzieht und deine Website in eine
                                unaufhaltsame Geldmaschine verwandelt.
                            </p>
                            <img className="we-box-image" src={require("../assets/img/webdesign.png")} alt="Webdesign Preview" />
                        </div>

                        {/**This column is centered */}
                        <div className="we-box-column we-box-center fade-in-bottom hidden">
                            <div className="we-box-slim">
                                <h3 className="we-box-title">Web Analytics</h3>
                                <p className="we-box-text">
                                    Erhalte wertvolle Einblicke, um das Nutzererlebnis zu verbessern und
                                    deine Umsätze zu erhöhen.
                                </p>
                            </div>
                            <div className="we-box-slim">
                                <h3 className="we-box-title">SEO & SEA</h3>
                                <p className="we-box-text">
                                    Durch SEO und Google Ads werden Sie sichtbarer und erhalten mehr
                                    qualifizierte Anfragen.
                                </p>
                            </div>
                        </div>

                        {/**This box needs to be on the right */}
                        <div className="we-box-medium we-box-right fade-in-right hidden">
                            <h3 className="we-box-title">Webentwicklung</h3>
                            <p className="we-box-text">
                                Egal ob CMS oder individuelle Lösung mit React und Laravel – wir bieten
                                dir eine wartungsfreundliche, skalierbare Website mit sauberem Code und
                                moderner Technologie, die langfristig performt.
                            </p>
                            <div className="we-box-image-container">
                                {/* First image row */}
                                <div className="we-box-image-row">
                                    <img className="we-box-logo-images" src={require("../assets/img/react-logo.png")} alt="React" />
                                    <img className="we-box-logo-images" src={require("../assets/img/wordpress-logo.png")} alt="WordPress" />
                                </div>

                                {/* Second image row */}
                                <div className="we-box-image-row">
                                    <img className="we-box-logo-images" src={require("../assets/img/laravel-logo.png")} alt="Laravel" />
                                    <img className="we-box-logo-images" src={require("../assets/img/webflow-logo.png")} alt="Webflow" />
                                </div>

                            </div>
                        </div>
                    </div>

                    <h3 className="small-title fade-in-bottom hidden animation-delay-long">Auch Content</h3>

                    {/* Second Row */}
                    <div className="we-box-row">

                        {/* this one takes left and middle positions */}
                        <div className="we-box-large we-box-right fade-in-left hidden animation-delay-long">
                            <video
                                className="we-box-video"
                                src={require("../assets/video/content-video.mp4")}
                                autoPlay
                                loop
                                muted
                                playsInline>
                                Your browser does not support the video tag.
                            </video>
                        </div>


                        {/* This column needs to be on the right of content */}
                        <div className="we-box-column we-box-right fade-in-right hidden animation-delay-extreme">
                            <div className="we-box-slim">
                                <h3 className="we-box-title">Planung & Produktion</h3>
                                <p className="we-box-text">
                                    Keine 0815-Planung. Wir setzen auf durchdachte Konzepte in der Foto-
                                    und Videografie, die deine Vision perfekt einfangen.
                                </p>
                            </div>
                            <div className="we-box-slim">
                                <h3 className="we-box-title">SFX & VFX</h3>
                                <p className="we-box-text">
                                    SFX und VFX bringen deine Inhalte auf das nächste Level – durch
                                    spektakuläre visuelle Effekte und nahtlose Integration.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className="mobile">
                    <h2 className="medium-title">Was Bieten Wir?</h2>
                    <h3 className="small-title">Wir Kreieren</h3>

                    {/* First Row */}
                    <div className="horizontal-scroll-container">
                        <div className="we-box-row">
                            {/* This box needs to be on the left */}
                            <div className="we-box-medium fade-in-left hidden">
                                <h3 className="we-box-title">Webdesign</h3>
                                <p className="we-box-text">
                                    Ein marktführendes Design, das Kunden anzieht und deine Website in eine
                                    unaufhaltsame Geldmaschine verwandelt.
                                </p>
                                <img className="we-box-image" src={require("../assets/img/webdesign.png")} alt="Webdesign Preview" />
                            </div>

                            {/* This column is centered */}
                            <div className="we-box-column fade-in-bottom hidden">
                                <div className="we-box-slim">
                                    <h3 className="we-box-title">Web Analytics</h3>
                                    <p className="we-box-text">
                                        Erhalte wertvolle Einblicke, um das Nutzererlebnis zu verbessern und
                                        deine Umsätze zu erhöhen.
                                    </p>
                                </div>
                                <div className="we-box-slim">
                                    <h3 className="we-box-title">SEO & SEA</h3>
                                    <p className="we-box-text">
                                        Durch SEO und Google Ads werden Sie sichtbarer und erhalten mehr
                                        qualifizierte Anfragen.
                                    </p>
                                </div>
                            </div>

                            {/* This box needs to be on the right */}
                            <div className="we-box-medium fade-in-right hidden">
                                <h3 className="we-box-title">Webentwicklung</h3>
                                <p className="we-box-text">
                                    Egal ob CMS oder individuelle Lösung mit React und Laravel – wir bieten
                                    dir eine wartungsfreundliche, skalierbare Website mit sauberem Code und
                                    moderner Technologie, die langfristig performt.
                                </p>
                                <div className="we-box-image-container">
                                    {/* First image row */}
                                    <div className="we-box-image-row">
                                        <img className="we-box-logo-images" src={require("../assets/img/react-logo.png")} alt="React" />
                                        <img className="we-box-logo-images" src={require("../assets/img/wordpress-logo.png")} alt="WordPress" />
                                    </div>
                                    {/* Second image row */}
                                    <div className="we-box-image-row">
                                        <img className="we-box-logo-images" src={require("../assets/img/laravel-logo.png")} alt="Laravel" />
                                        <img className="we-box-logo-images" src={require("../assets/img/webflow-logo.png")} alt="Webflow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <h3 className="small-title fade-in-bottom hidden animation-delay-long">Auch Content</h3>

                    {/* Second Row */}
                    <div className="horizontal-scroll-container">
                        <div className="we-box-row">
                            {/* This one takes left and middle positions */}
                            <div className="we-box-large fade-in-left hidden animation-delay-long">
                                <video
                                    className="we-box-video"
                                    src={require("../assets/video/content-video.mp4")}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline>
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="we-box-slim">
                                <h3 className="we-box-title">Planung & Produktion</h3>
                                <p className="we-box-text">
                                    Keine 0815-Planung. Wir setzen auf durchdachte Konzepte in der Foto-
                                    und Videografie, die deine Vision perfekt einfangen.
                                </p>
                            </div>
                            <div className="we-box-slim">
                                <h3 className="we-box-title">SFX & VFX</h3>
                                <p className="we-box-text">
                                    SFX und VFX bringen deine Inhalte auf das nächste Level – durch
                                    spektakuläre visuelle Effekte und nahtlose Integration.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </section >

            {/* Wir Vermarkten */}
            < section className="section-style we-market" >
                <h3 className="small-title fade-in-bottom hidden animation-delay-long">Wir Vermarkten</h3>


                {/* Desktop */}
                <div className="desktop">
                    <div className="we-box-row">

                        {/**This box needs to be on the left */}
                        <div className="we-box-medium we-box-left fade-in-left hidden animation-delay-long">
                            <div className="we-box-header-row">
                                <h3 className="we-box-title">Killer Copy</h3>
                                <div className="we-box-inline-img">
                                    <img src={require("../assets/gifs/fire.gif")} alt="Fire Icon" className="gif-icon" />


                                </div>

                            </div>
                            <p className="we-box-text">
                                Hör auf, Geld für lahme Ads zu verbrennen, die keiner
                                sehen will! Wir wissen, was deine Kunden wirklich
                                wollen, und servieren es ihnen auf dem Silbertablett
                                ohne Bullshit, nur knallharte Lösungen, die treffen.
                                Mit bewährten Strategien, die schon unzählige
                                Businesses an die Spitze katapultiert haben, reißen
                                wir dein Unternehmen aus der Stagnation und
                                schießen es direkt durch die Decke. Wenn du bereit
                                bist, aufzuhören, klein zu denken, <CalendlyButtons linkText="dann lass uns loslegen!" />
                            </p>
                        </div>



                        {/**This column is centered */}
                        <div className="we-box-column we-box-center fade-in-bottom hidden animation-delay-longer">
                            <div className="we-box-slim">
                                <div className="we-box-header-row">
                                    <h3 className="we-box-title">Meta Ads</h3>
                                    <div className="we-box-inline-img">
                                        <img src={require("../assets/img/meta-logo.png")} alt="Meta Ads Icon" className="we-box-inline-image" />
                                    </div>
                                </div>

                                <p className="we-box-text we-box-text-small">
                                    Meta Ads lässt dich genau in den Feeds deiner idealen
                                    Kunden auftauchen, bevor sie überhaupt wissen, dass
                                    sie dich brauchen. Punktgenaues Targeting sorgt dafür,
                                    dass du Verkäufe einfährst, statt Likes zu sammeln.
                                </p>
                            </div>
                            <div className="we-box-slim">
                                <div className="we-box-header-row">
                                    <h3 className="we-box-title">Google Ads</h3>
                                    <div className="we-box-inline-img">
                                        <img src={require("../assets/img/google-ads.png")} alt="Google Ads Icon" className="we-box-inline-image" />
                                    </div>
                                </div>

                                <p className="we-box-text we-box-text-small">
                                    Google Ads bringt dich direkt vor deine heißesten Käufer
                                    genau dann, wenn sie nach einer Lösung suchen.
                                    Mit gezielten Keywords lenkst du den Traffic direkt
                                    auf dein Angebot.
                                </p>
                            </div>
                        </div>

                        {/**This box needs to be on the right */}
                        <div className="we-box-medium we-box-right fade-in-right hidden animation-delay-extreme">
                            <h3 className="we-box-title">Account Managment</h3>
                            <p className="we-box-text">
                                Das Beste? Wir machen alles für dich.
                                Zielkunden-Recherche, Kampagnen-Setup,
                                Strukturierung, und das volle Management deiner
                                Social-Media-Präsenz. Dein Part? Füße hochlegen
                                und das Geld zählen.
                            </p>
                            <div className="we-box-image-container">
                                {/* First image row */}
                                <div className="we-box-image-row">
                                    <img className="we-box-large-gif" src={require("../assets/gifs/hasbulla.gif")} alt="React" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className="mobile">
                    <div className="horizontal-scroll-container">
                        <div className="we-box-row">

                            {/**This box needs to be on the left */}
                            <div className="we-box-medium we-box-left fade-in-left hidden animation-delay-long">
                                <div className="we-box-header-row">
                                    <h3 className="we-box-title">Killer Copy</h3>
                                    <div className="we-box-inline-img">
                                        <img src={require("../assets/gifs/fire.gif")} alt="Fire Icon" className="gif-icon" />


                                    </div>

                                </div>
                                <p className="we-box-text">
                                    Hör auf, Geld für lahme Ads zu verbrennen, die keiner
                                    sehen will! Wir wissen, was deine Kunden wirklich
                                    wollen, und servieren es ihnen auf dem Silbertablett
                                    ohne Bullshit, nur knallharte Lösungen, die treffen.
                                    Mit bewährten Strategien, die schon unzählige
                                    Businesses an die Spitze katapultiert haben, reißen
                                    wir dein Unternehmen aus der Stagnation und
                                    schießen es direkt durch die Decke. Wenn du bereit
                                    bist, aufzuhören, klein zu denken, <CalendlyButtons linkText="dann lass uns loslegen!" />
                                </p>
                            </div>



                            {/**This column is centered */}
                            <div className="we-box-column we-box-center fade-in-bottom hidden animation-delay-longer">
                                <div className="we-box-slim">
                                    <div className="we-box-header-row">
                                        <h3 className="we-box-title">Meta Ads</h3>
                                        <div className="we-box-inline-img">
                                            <img src={require("../assets/img/meta-logo.png")} alt="Meta Ads Icon" className="we-box-inline-image" />
                                        </div>
                                    </div>

                                    <p className="we-box-text we-box-text-small">
                                        Meta Ads lässt dich genau in den Feeds deiner idealen
                                        Kunden auftauchen, bevor sie überhaupt wissen, dass
                                        sie dich brauchen. Punktgenaues Targeting sorgt dafür,
                                        dass du Verkäufe einfährst, statt Likes zu sammeln.
                                    </p>
                                </div>
                                <div className="we-box-slim">
                                    <div className="we-box-header-row">
                                        <h3 className="we-box-title">Google Ads</h3>
                                        <div className="we-box-inline-img">
                                            <img src={require("../assets/img/google-ads.png")} alt="Google Ads Icon" className="we-box-inline-image" />
                                        </div>
                                    </div>

                                    <p className="we-box-text we-box-text-small">
                                        Google Ads bringt dich direkt vor deine heißesten Käufer
                                        genau dann, wenn sie nach einer Lösung suchen.
                                        Mit gezielten Keywords lenkst du den Traffic direkt
                                        auf dein Angebot.
                                    </p>
                                </div>
                            </div>

                            {/**This box needs to be on the right */}
                            <div className="we-box-medium we-box-right fade-in-right hidden animation-delay-extreme">
                                <h3 className="we-box-title">Account Managment</h3>
                                <p className="we-box-text">
                                    Das Beste? Wir machen alles für dich.
                                    Zielkunden-Recherche, Kampagnen-Setup,
                                    Strukturierung, und das volle Management deiner
                                    Social-Media-Präsenz. Dein Part? Füße hochlegen
                                    und das Geld zählen.
                                </p>
                                <div className="we-box-image-container">
                                    {/* First image row */}
                                    <div className="we-box-image-row">
                                        <img className="we-box-large-gif" src={require("../assets/gifs/hasbulla.gif")} alt="React" />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </section >



            <section className="section-style we-optimize">
                <h3 className="small-title fade-in-bottom hidden animation-delay-short">WIR OPTIMIEREN</h3>

                {/* Desktop */}
                <div className="desktop">
                    <div className="we-box-extralarge fade-in-bottom hidden animation-delay-long">
                        <div className="we-box-row we-box-row-override">
                            {/* Left Side: GIF */}
                            <div className="we-optimize-image-container">
                                <img
                                    src={require("../assets/gifs/toystory.gif")}
                                    alt="Optimization GIF"
                                    className="we-box-image-left"
                                />
                            </div>

                            {/* Right Side: Header and Texts */}
                            <div className="we-optimize-content-container">
                                <h3 className="we-optimize-title">
                                    Verwandle deine aktuelle Website in einen Goldesel
                                </h3>
                                {/* First Row: Text Block 1 & 2 */}
                                <div className="we-optimize-row">
                                    <div className="we-optimize-text-block">
                                        <h4 className="large-text">ROI Traum</h4>
                                        <p>
                                            Cool, was man alles über den Kunden weiß, als sei
                                            man der FBI. Absolute Longsleeve für mehr Cash.
                                        </p>
                                    </div>
                                    <div className="we-optimize-text-block">
                                        <h4 className="large-text">CTA auf Steroiden</h4>
                                        <p>
                                            Ein einfaches Button kann einen Chance, als ob du
                                            an eine Tür klopft, doppeln.
                                        </p>
                                    </div>
                                </div>

                                {/* Second Row: Text Block 3 & 4 */}
                                <div className="we-optimize-row">
                                    <div className="we-optimize-text-block">
                                        <h4 className="large-text">Ranking</h4>
                                        <p>
                                            Einmal wie "Ohne Tomaten auf Geld" auf Google zu
                                            ranken. Vielleicht das Geheimnis zur Macht.
                                        </p>
                                    </div>
                                    <div className="we-optimize-text-block">
                                        <h4 className="large-text">CRO 2-3x Anfragen</h4>
                                        <p>
                                            Verblüffende Anfragen durch Quality Leads erhöhen
                                            und das auf Dauer.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="we-box-row we-optimize-bottom">
                        <div className="we-box-slim we-box-optimize fade-in-left hidden animation-delay-longer">
                            <div className="we-box-header-row">
                                <h3 className="we-box-title">CIA Level Tracking</h3>
                            </div>

                            <p className="we-box-text we-box-text-small">
                                Mit einem Tracking-Plan, der deine Website wie unter
                                dem Mikroskop seziert – wir zeigen dir jeden Klick,
                                jeden Moment, in dem dein Kunde zögert, und jeden
                                verdammten Atemzug. Keine blinden Flecken mehr,
                                nur Daten, die dir helfen, alles rauszuholen.
                            </p>
                        </div>

                        <div className="we-box-slim we-box-optimize fade-in-bottom hidden animation-delay-long">
                            <div className="we-box-header-row">
                                <h3 className="we-box-title">Malen nach zahlen</h3>
                            </div>

                            <p className="we-box-text we-box-text-small">
                                Unser Dashboard zeigt dir alles, was Google Analytics
                                übersieht.. jeden Schritt deines Users, von A bis Z.
                                So kannst du präzises A/B-Testing betreiben und
                                deinen Umsatz organisch steigern, ohne dabei
                                unnötigen Aufwand zu haben.
                            </p>
                        </div>

                        <div className="we-box-slim we-box-optimize fade-in-right hidden animation-delay-long">
                            <div className="we-box-header-row">
                                <h3 className="we-box-title">DSGVO Konform</h3>
                            </div>

                            <p className="we-box-text we-box-text-small">
                                Google Ads bringt dich direkt vor deine heißesten Käufer
                                genau dann, wenn sie nach einer Lösung suchen.
                                Mit gezielten Keywords lenkst du den Traffic direkt
                                auf dein Angebot.
                            </p>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className="mobile">
                    <div className="horizontal-scroll-container">
                        <div className="we-box-extralarge fade-in-bottom hidden animation-delay-long">
                            <div className="horizontal-scroll-content">
                                {/* Left Side: GIF */}
                                <div className="we-optimize-image-container">
                                    <img
                                        src={require("../assets/gifs/toystory.gif")}
                                        alt="Optimization GIF"
                                        className="we-box-image-left"
                                    />
                                </div>

                                {/* Right Side: Header and Texts */}
                                <div className="we-optimize-text-block">
                                    <h4 className="large-text">ROI Traum</h4>
                                    <p>
                                        Cool, was man alles über den Kunden weiß, als sei
                                        man der FBI. Absolute Longsleeve für mehr Cash.
                                    </p>
                                </div>

                                <div className="we-optimize-text-block">
                                    <h4 className="large-text">CTA auf Steroiden</h4>
                                    <p>
                                        Ein einfaches Button kann einen Chance, als ob du
                                        an eine Tür klopft, doppeln.
                                    </p>
                                </div>

                                <div className="we-optimize-text-block">
                                    <h4 className="large-text">Ranking</h4>
                                    <p>
                                        Einmal wie "Ohne Tomaten auf Geld" auf Google zu
                                        ranken. Vielleicht das Geheimnis zur Macht.
                                    </p>
                                </div>

                                <div className="we-optimize-text-block">
                                    <h4 className="large-text">CRO 2-3x Anfragen</h4>
                                    <p>
                                        Verblüffende Anfragen durch Quality Leads erhöhen
                                        und das auf Dauer.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="horizontal-scroll-container">
                        <div className="we-box-row we-optimize-bottom">
                            <div className="we-box-slim we-box-optimize fade-in-left hidden animation-delay-longer">
                                <div className="we-box-header-row">
                                    <h3 className="we-box-title">CIA Level Tracking</h3>
                                </div>

                                <p className="we-box-text we-box-text-small">
                                    Mit einem Tracking-Plan, der deine Website wie unter
                                    dem Mikroskop seziert – wir zeigen dir jeden Klick,
                                    jeden Moment, in dem dein Kunde zögert, und jeden
                                    verdammten Atemzug. Keine blinden Flecken mehr,
                                    nur Daten, die dir helfen, alles rauszuholen.
                                </p>
                            </div>

                            <div className="we-box-slim we-box-optimize fade-in-bottom hidden animation-delay-long">
                                <div className="we-box-header-row">
                                    <h3 className="we-box-title">Malen nach zahlen</h3>
                                </div>

                                <p className="we-box-text we-box-text-small">
                                    Unser Dashboard zeigt dir alles, was Google Analytics
                                    übersieht.. jeden Schritt deines Users, von A bis Z.
                                    So kannst du präzises A/B-Testing betreiben und
                                    deinen Umsatz organisch steigern, ohne dabei
                                    unnötigen Aufwand zu haben.
                                </p>
                            </div>

                            <div className="we-box-slim we-box-optimize fade-in-right hidden animation-delay-long">
                                <div className="we-box-header-row">
                                    <h3 className="we-box-title">DSGVO Konform</h3>
                                </div>

                                <p className="we-box-text we-box-text-small">
                                    Google Ads bringt dich direkt vor deine heißesten Käufer
                                    genau dann, wenn sie nach einer Lösung suchen.
                                    Mit gezielten Keywords lenkst du den Traffic direkt
                                    auf dein Angebot.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>

                <CallbackForm />
            </section >

            <section className="section-style why-section">
                <h2 className="medium-title fade-in-bottom hidden">
                    Bist du bereit für Adestoria?
                </h2>

                <br />

                <div className="why-content fade-in-bottom hidden">
                    <p>
                        Mit einem Team von 4 Freelancern, die jeweils mindestens 5 Jahre Erfahrung
                        in ihren Bereichen haben, kam eine Realisation: <span className="special-text">90% </span> der Agenturen <span className="special-text">machen </span>
                        <span className="special-text">denselben Mist</span>, teils nicht mal anders verpackt.<br />
                        <br />
                        Es wird nur auf den schnellen Profit gesetzt, mit überteuerten Dienstleistungen,
                        die letztlich nur Template-Lösungen sind. Von 40€-Templates für
                        WordPress, die für 10.000€ bis 25.000€ verkauft werden,
                        bis hin zu eintönigen Videos sei es für Recruiting, Lead-Generierung etc.
                        die größtenteils übersprungen werden und nur durch hohe
                        Marketingbudgets zu "guten" Resultaten führen.<br />
                        <br />
                        <span className="special-text">Das Hauptproblem? Eine unklare Kommunikation des tatsächlichen Mehrwerts.</span><br />
                        <br />
                        Mit Adestoria setzen wir nicht auf Emotionen oder "Erfahrungen von anderen", um unsere
                        Dienstleistungen zu verkaufen. Stattdessen konzentrieren wir uns auf das, was wirklich zählt:
                        Web-Analyse . Denn anhand von Daten können wir logische Schlussfolgerungen nachvollziehen
                        und Sie entsprechend für Wachstum positionieren. So treffen
                        Sie die besten Entscheidungen für langfristigen Erfolg.<br />
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />

                    <h2 className="medium-title fade-in-bottom hidden">Lust direkt zu starten?</h2>
                    <CalendlyButtons buttonText="JETZT KONTAKT AUFNEHMEN" standalone={true} />
                </div>
            </section>







            {/* Add later */}
            {/*
            <section className="section-style contact-section">

            </section>
            <section className="section-style faq-section">
                <Faq />
            </section>
            */}



            {/* GIF Overlay */}
            {
                showGif && (
                    <div className="gif-overlay" onClick={() => setShowGif(false)}>
                        <div className="gif-container">
                            <img src={gifSrc} alt="Old Lady with Sunglasses" />
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default Home;
