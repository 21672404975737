import React from "react";
import "./logo-carousel.css";

import gyeon from "../assets/img/logos/gyeon_250p.png";
import hamm from "../assets/img/logos/Hamm Logo 250p.png";
import hk from "../assets/img/logos/HK Logo 250p.png";
import mm from "../assets/img/logos/MM Logo 250p.png";
import nicco from "../assets/img/logos/nicco brun 250p.png";
import siind from "../assets/img/logos/Siind Logo 250p.png";
import thezign from "../assets/img/logos/the zign 250p.png";
import theview from "../assets/img/logos/theview logo 250p.png";
import vibefactory from "../assets/img/logos/Vibefactory logo 250p.png";
import classicremise from "../assets/img/logos/Classic Remise.svg";

const LogoCarousel = () => {
    const logos = [
        { src: gyeon, alt: "Gyeon Logo" },
        { src: hamm, alt: "Hamm Logo" },
        { src: hk, alt: "HK Logo" },
        { src: mm, alt: "MM Logo" },
        { src: nicco, alt: "Nicco Brun Logo" },
        { src: siind, alt: "Siind Logo" },
        { src: thezign, alt: "The Zign Logo" },
        { src: theview, alt: "The View Logo" },
        { src: vibefactory, alt: "Vibefactory Logo" },
        //{ src: classicremise, alt: "Classic Remise Logo" }
    ];

    // Duplicate logos to ensure a continuous loop
    const duplicatedLogos = [...logos, ...logos];

    return (
        <div className="carousel-container fade-in-bottom hidden animation-delay-long">
            <div className="carousel-track">
                {duplicatedLogos.map((logo, index) => (
                    <div className="carousel-slide" key={index}>
                        <img src={logo.src} alt={logo.alt} className="carousel-logo" />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default LogoCarousel;
