import React, { useState, useRef, useEffect } from "react";
import { parsePhoneNumberFromString, AsYouType } from "libphonenumber-js";
import DOMPurify from "dompurify";
import "./callBackForm.css";
import callbackGif from "../../assets/gifs/callback.gif";
import axios from "axios";

const CallbackForm = () => {
   const [step, setStep] = useState(1);
   const [name, setName] = useState("");
   const [phoneNumber, setPhoneNumber] = useState("");
   const [formattedNumber, setFormattedNumber] = useState("");
   const [errorMessage, setErrorMessage] = useState("");
   const [showPopup, setShowPopup] = useState(false);
   const [isSubmitted, setIsSubmitted] = useState(false);
   const [isSubmitting, setIsSubmitting] = useState(false);
   const phoneInputRef = useRef(null);

   // API base URL (supports environment variables)
   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "https://api.adestoria.com/api";
   console.log(API_BASE_URL); // Debugging: Verify the value here



   useEffect(() => {
      if (step === 2 && phoneInputRef.current) {
         phoneInputRef.current.focus();
      }
   }, [step]);

   const getDynamicTitle = () => {
      const sanitizedName = DOMPurify.sanitize(name);
      const sanitizedNumber = DOMPurify.sanitize(formattedNumber);

      if (isSubmitted) {
         return (
            <>
               Hey {sanitizedName}{" "}
               <span className="wave" role="img" aria-label="waving hand">
                  👋
               </span>
               ,<br /> wir rufen dich in Kürze <br /> unter der ({sanitizedNumber}) an!
            </>
         );
      }
      return step === 1
         ? "Lass dich direkt beraten!"
         : `${sanitizedNumber || "Telefonnummer"} und los!`;
   };

   const handleSubmit = async (e) => {
      e.preventDefault();

      if (isSubmitting) return;

      setIsSubmitting(true);

      try {
         if (step === 1) {
            if (!validateName(name)) return;
            setStep(2);
         } else if (step === 2) {
            if (!validatePhoneNumber(phoneNumber)) return;

            // Submit the form
            await submitForm();
            showSuccessPopup();
         }
      } catch (error) {
         console.error("Submission Error:", error);
         setErrorMessage("An error occurred while submitting the form. Please try again later.");
      } finally {
         setIsSubmitting(false);
      }
   };

   const validateName = (name) => {
      const sanitized = name.trim().replace(/[^a-zA-ZäöüÄÖÜß\s-]/g, "");
      if (sanitized.length < 2) {
         setErrorMessage("Bitte gib einen gültigen Namen ein.");
         return false;
      }
      setErrorMessage("");
      return true;
   };

   const validatePhoneNumber = (number) => {
      const parsedNumber = parsePhoneNumberFromString(number, "DE");
      if (!parsedNumber || !parsedNumber.isValid()) {
         setErrorMessage("Bitte gib eine gültige Rufnummer ein.");
         return false;
      }
      setErrorMessage("");
      return true;
   };

   const handlePhoneInput = (e) => {
      const rawValue = e.target.value.replace(/\s/g, "");
      setPhoneNumber(rawValue);

      const formatter = new AsYouType("DE");
      setFormattedNumber(formatter.input(rawValue));
   };

   const submitForm = async () => {
      try {
         const response = await axios.post(`${API_BASE_URL}/v1/submit-form`, {
            formType: "callback",
            name,
            phone: phoneNumber,
         });
         console.log("Backend response:", response.data);
      } catch (error) {
         console.error("Error submitting the form:", error.response?.data || error.message);
         setErrorMessage("Ein Fehler ist aufgetreten. Bitte versuche es später erneut.");
         throw error;
      }
   };

   const showSuccessPopup = () => {
      setShowPopup(true);
      setIsSubmitted(true);
      setTimeout(() => setShowPopup(false), 15000);
   };

   return (
      <div className="callback-container">
         <h3 className="small-title fade-in-bottom override-small-title">{getDynamicTitle()}</h3>
         {!isSubmitted && (
            <form onSubmit={handleSubmit} className="cbf-form">
               {step === 1 && (
                  <div className="cbf-input-wrapper fade-in">
                     <input
                        type="text"
                        className="cbf-input-field"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Geben Sie Ihren Namen ein"
                        maxLength={50}
                        required
                     />
                     <button type="submit" className="cbf-submit-button">
                        WEITER
                     </button>
                  </div>
               )}
               {step === 2 && (
                  <div className="cbf-input-wrapper fade-in">
                     <input
                        type="text"
                        ref={phoneInputRef}
                        className="cbf-input-field"
                        value={formattedNumber}
                        onChange={handlePhoneInput}
                        placeholder="Gib deine Telefonnummer ein"
                        maxLength={20}
                        required
                     />
                     <button type="submit" className="cbf-submit-button">
                        ABSCHICKEN
                     </button>
                  </div>
               )}
               {errorMessage && <p className="cbf-error-message pulse">{errorMessage}</p>}
            </form>
         )}
         {showPopup && (
            <div className="cbf-popup fade-in" onClick={() => setShowPopup(false)}>
               <div className="cbf-popup-content">
                  <h1 className="large-title">
                     Hey {DOMPurify.sanitize(name)}{" "}
                     <span className="wave" role="img" aria-label="waving hand">
                        👋
                     </span>
                     , wir rufen dich in Kürze an!
                  </h1>
                  <img src={callbackGif} alt="Success" className="cbf-popup-gif" />
               </div>
            </div>
         )}
      </div>
   );
};

export default CallbackForm;
