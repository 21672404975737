import React from 'react';

const TrustpilotWidget = () => {
   return (
      <div className="small-text-item">
         {/* Desktop */}
         <a href="https://de.trustpilot.com/review/adestoria.com" target="_blank" rel="noopener noreferrer" className="desktop">
            ⭐⭐⭐⭐ 4.4 Sterne aus 10 Bewertungen
         </a>
         {/* Mobile */}
         <a href="https://de.trustpilot.com/review/adestoria.com" target="_blank" rel="noopener noreferrer" className='mobile'>
            ⭐⭐⭐⭐ 4.4 Sterne aus 10 Bewertungen
         </a>
      </div>

   );
};

export default TrustpilotWidget;
