// ComingSoon.js or NotFound.js
import React from 'react';

const ComingSoon = () => {
   return (
      <div className="section-style">
         <h1>Coming Soon!</h1>
         <p>We're working on this page. Stay tuned!</p>
      </div >
   );
};

export default ComingSoon;
