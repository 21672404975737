import React, { useEffect } from 'react';
import { PopupButton } from 'react-calendly';
import './CalendlyForm.css';

const CalendlyButtons = ({
    buttonText = "TERMIN VEREINBAREN",
    standalone = false,
    linkText = null,
    inline = false,
    smallButton = false,
    customClass = "" // Custom class for additional styling
}) => {
    const calendlyUrl = 'https://calendly.com/adestoria/kennenlernen';

    useEffect(() => {
        const scriptId = 'calendly-widget-script';

        if (!document.getElementById(scriptId)) {
            const script = document.createElement('script');
            script.id = scriptId;
            script.src = 'https://assets.calendly.com/assets/external/widget.js';
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    const handleLinkClick = (e) => {
        e.preventDefault();
        if (window.Calendly && typeof window.Calendly.initPopupWidget === 'function') {
            window.Calendly.initPopupWidget({ url: calendlyUrl });
        } else {
            console.error('Calendly script not ready. Refresh the page or try again.');
        }
    };

    if (inline) {
        return (
            <div
                className={`calendly-inline-widget ${customClass}`}
                data-url={calendlyUrl}
                style={{ minWidth: "320px", height: "700px", overflow: "hidden" }}
            ></div>
        );
    }

    if (linkText) {
        return (
            <a
                href="#"
                onClick={handleLinkClick}
                className={`calendly-link ${customClass}`}
            >
                {linkText}
            </a>
        );
    }

    return (
        <div className={`calendly-buttons ${customClass}`}>
            {smallButton && (
                <div>
                    {/* Desktop */}
                    <PopupButton
                        url={calendlyUrl}
                        rootElement={document.getElementById('root')}
                        text={buttonText}
                        className={`button small desktop ${customClass}`}
                    />

                    {/* Mobile */}
                    <PopupButton
                        url={calendlyUrl}
                        rootElement={document.getElementById('root')}
                        text={buttonText}
                        className={`button small mobile ${customClass}`}
                    />
                </div>
            )}

            {!standalone && !smallButton && (
                <div className="hero-buttons">
                    {/* Desktop */}
                    <div className="desktop">
                        <input
                            type="text"
                            placeholder="Sparen wir uns das Zögern und sprechen wir direkt."
                            className={`input-field ${customClass}`}
                            disabled
                        />
                        <PopupButton
                            url={calendlyUrl}
                            rootElement={document.getElementById('root')}
                            text="DIREKT STARTEN"
                            className={`button submit-button ${customClass}`}
                        />
                    </div>

                    {/* Mobile */}
                    <div className="mobile">
                        <input
                            type="text"
                            placeholder="Sparen wir uns das Zögern und sprechen wir direkt."
                            className={`input-field ${customClass}`}
                            disabled
                        />
                        <PopupButton
                            url={calendlyUrl}
                            rootElement={document.getElementById('root')}
                            text="DIREKT STARTEN"
                            className={`button submit-button ${customClass}`}
                        />
                    </div>
                </div>
            )}

            {standalone && !smallButton && (
                <div>
                    <div className="desktop">
                        <PopupButton
                            url={calendlyUrl}
                            rootElement={document.getElementById('root')}
                            text={buttonText || "HIER CLICKEN"}
                            className={`button extralarge sheen fade-in-bottom hidden animation-delay-long ${customClass}`}
                        />
                    </div>
                    <div className="mobile">
                        <PopupButton
                            url={calendlyUrl}
                            rootElement={document.getElementById('root')}
                            text={buttonText || "HIER CLICKEN"}
                            className={`button widelarge sheen fade-in-bottom hidden animation-delay-long ${customClass}`}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CalendlyButtons;
