import React from 'react';
import './legal.css';

const PrivacyPolicy = () => {
   return (
      <div className="legal-container">
         <h1 className="medium-title">Datenschutzerklärung</h1>

         {/* § 1 Verantwortlicher */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 1 Verantwortlicher</h2>
            <p className="medium-text">
               Verantwortlich für die Datenverarbeitung auf dieser Website ist die <strong>Adestoria Agentur</strong>, vertreten durch Enriko Korezki, Stralsunder Straße 2, 40595 Düsseldorf, Deutschland.
            </p>
            <p className="medium-text">
               Kontakt: <br />
               E-Mail: <a href="mailto:enriko@adestoria.com">enriko@adestoria.com</a> <br />
               Telefon: +49 015232058789
            </p>
         </div>

         {/* § 2 Erhebung und Speicherung personenbezogener Daten */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 2 Erhebung und Speicherung personenbezogener Daten</h2>
            <p className="medium-text">
               Wir erheben und speichern personenbezogene Daten, wenn Sie unsere Website besuchen oder unsere Dienstleistungen nutzen. Dazu gehören:
            </p>
            <ul className="medium-text">
               <li>IP-Adresse des zugreifenden Endgeräts</li>
               <li>Datum und Uhrzeit des Zugriffs</li>
               <li>Besuchte Seiten und Unterseiten</li>
               <li>Von Ihnen bereitgestellte Informationen, wie Name, E-Mail-Adresse und Telefonnummer</li>
            </ul>
         </div>

         {/* § 3 Zweck der Datenverarbeitung */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 3 Zweck der Datenverarbeitung</h2>
            <p className="medium-text">
               Ihre Daten werden zu folgenden Zwecken verarbeitet:
            </p>
            <ul className="medium-text">
               <li>Bereitstellung und Optimierung unserer Website</li>
               <li>Bearbeitung von Kontaktanfragen und Kundenkommunikation</li>
               <li>Erfüllung vertraglicher Verpflichtungen</li>
               <li>Analyse und Verbesserung unserer Dienstleistungen</li>
            </ul>
            <p className="medium-text">
               Die Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. b DSGVO (Erfüllung eines Vertrags) und Art. 6 Abs. 1 lit. f DSGVO (berechtigtes Interesse).
            </p>
         </div>

         {/* § 4 Weitergabe von Daten */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 4 Weitergabe von Daten</h2>
            <p className="medium-text">
               Eine Weitergabe Ihrer personenbezogenen Daten erfolgt nur, wenn:
            </p>
            <ul className="medium-text">
               <li>Dies zur Erfüllung eines Vertrags erforderlich ist (z. B. an Zahlungsdienstleister).</li>
               <li>Sie Ihre ausdrückliche Einwilligung erteilt haben.</li>
               <li>Dies aufgrund gesetzlicher Verpflichtungen notwendig ist.</li>
            </ul>
         </div>

         {/* § 5 Cookies */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 5 Cookies</h2>
            <p className="medium-text">
               Unsere Website verwendet Cookies, um die Nutzererfahrung zu verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden. Die Verwendung umfasst:
            </p>
            <ul className="medium-text">
               <li>Essenzielle Cookies, die für den Betrieb der Website erforderlich sind.</li>
               <li>Analyse-Cookies, die uns helfen, die Nutzung unserer Website besser zu verstehen.</li>
            </ul>
            <p className="medium-text">
               Sie können die Speicherung von Cookies in den Einstellungen Ihres Browsers verhindern oder einschränken.
            </p>
         </div>

         {/* § 6 Ihre Rechte */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 6 Ihre Rechte</h2>
            <p className="medium-text">
               Als Betroffener der Datenverarbeitung haben Sie folgende Rechte:
            </p>
            <ul className="medium-text">
               <li>Recht auf Auskunft über die von uns gespeicherten Daten (Art. 15 DSGVO)</li>
               <li>Recht auf Berichtigung unrichtiger Daten (Art. 16 DSGVO)</li>
               <li>Recht auf Löschung Ihrer Daten (Art. 17 DSGVO), sofern keine gesetzlichen Aufbewahrungspflichten entgegenstehen</li>
               <li>Recht auf Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
               <li>Recht auf Datenübertragbarkeit (Art. 20 DSGVO)</li>
               <li>Recht auf Widerspruch gegen die Datenverarbeitung (Art. 21 DSGVO)</li>
            </ul>
            <p className="medium-text">
               Um diese Rechte auszuüben, wenden Sie sich bitte an: <a href="mailto:enriko@adestoria.com">enriko@adestoria.com</a>.
            </p>
         </div>

         {/* § 7 Speicherdauer */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 7 Speicherdauer</h2>
            <p className="medium-text">
               Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die oben genannten Zwecke erforderlich ist oder wir gesetzlich dazu verpflichtet sind.
            </p>
         </div>

         {/* § 8 Änderungen der Datenschutzerklärung */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 8 Änderungen der Datenschutzerklärung</h2>
            <p className="medium-text">
               Wir behalten uns vor, diese Datenschutzerklärung bei Bedarf anzupassen, beispielsweise bei Änderungen gesetzlicher Vorgaben oder Erweiterungen unserer Dienstleistungen.
            </p>
         </div>

         {/* § 9 Kontaktstelle für Datenschutzfragen */}
         <div className="legal-section">
            <h2 className="medium-text bold">§ 9 Kontaktstelle für Datenschutzfragen</h2>
            <p className="medium-text">
               Für Fragen zum Datenschutz können Sie sich jederzeit an uns wenden: <br />
               E-Mail: <a href="mailto:enriko@adestoria.com">enriko@adestoria.com</a>
            </p>
         </div>
      </div>
   );
};

export default PrivacyPolicy;
